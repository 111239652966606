import React from "react";
import up from "../../assets/img/uparrowgreen.png";
import down from "../../assets/img/downarrowred.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { formatData } from "../../utilities/formatData";

const InformationBox = ({
  dataThisWeek,
  dataLastWeek,
  description,
  type,
  selectedValue,
}) => {
  const { t } = useTranslation();

  if (type === "feedback") {
    return (
      <div className="box">
        <div className="box-information">
          {
            <div className="icon">
              {dataThisWeek >= dataLastWeek ? (
                <LazyLoadImage src={up} className="up-arrow-green" />
              ) : (
                <LazyLoadImage src={down} className="down-arrow-red" />
              )}
              <div className="box-number">
                {(dataThisWeek && formatData(dataThisWeek)) || 0}
                {type === "feedback" && (
                  <span className="taki-chat-userfeedback">/5</span>
                )}
              </div>
            </div>
          }
          <div className="box-number-last-week">
            <p>{description}</p>
            <p>
              {(dataLastWeek && formatData(dataLastWeek)) || 0}
              {"/5"} {t(`the ${selectedValue?.selected} before`)}
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="box">
      <div className="box-information">
        {
          <div className="icon">
            {dataThisWeek >= dataLastWeek ? (
              <LazyLoadImage src={up} className="up-arrow-green" />
            ) : (
              <LazyLoadImage src={down} className="down-arrow-red" />
            )}
            <div className="box-number">{dataThisWeek || 0}</div>
          </div>
        }
        <div className="box-number-last-week">
          <p>{description}</p>
          <p>
            {dataLastWeek || 0} {t(`the ${selectedValue?.selected} before`)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default InformationBox;
