import React, { useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { IconButton } from "@mui/material";
import Iconify from "./../Iconify";
import MuiPhoneNumber from "material-ui-phone-number";
import { useTranslation } from "react-i18next";
const Input = ({
  label,
  placeholder,
  name,
  errors,
  id,
  className,
  register,
  phoneNumber,
  disabled,
  labelClassname,
  type,
}) => {
  const { control } = useFormContext();
  const [showPassword, setShowPassword] = useState(false);
  const { i18n } = useTranslation();
  return (
    <div className="label-input-wrapper" dir="auto">
      <label dir="auto" className={labelClassname ? labelClassname : ""}>
        {label}
        {id && <sup style={{ color: "red" }}>*</sup>}
      </label>
      {phoneNumber ? (
        <Controller
          name={name}
          control={control}
          rules={{ required: true }}
          render={({ field, fieldState: { error } }) => {
            return (
              <>
                <MuiPhoneNumber
                  variant="outlined"
                  defaultCountry="tn"
                  fullWidth
                  name={name}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    field.onChange(e);
                  }}
                  error={Object?.keys(errors)?.includes(name) ? true : false}
                />
                {name === "phone" && <span>{errors?.phone?.message}</span>}
              </>
            );
          }}
        />
      ) : (
        <div
          className={
            Object?.keys(errors)?.includes(name)
              ? `input-Container ${className} input-container-error`
              : `input-Container ${className}`
          }
          dir="auto"
        >
          <Controller
            name={name}
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <input
                    disabled={
                      disabled === false
                        ? true
                        : disabled === true
                        ? false
                        : false
                    }
                    type={
                      type
                        ? type
                        : name === "email"
                        ? "email"
                        : !showPassword && name.includes("password")
                        ? "password"
                        : "text"
                    }
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    placeholder={placeholder}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                    }}
                    {...register(name)}
                  />

                  {name.includes("password") && (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      style={{
                        backgroundColor: "transparent",
                      }}
                      disableRipple
                    >
                      <Iconify
                        icon={
                          showPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                        }
                      />
                    </IconButton>
                  )}
                </>
              );
            }}
          />
        </div>
      )}
      {Object.keys(errors).map((fieldName, i) => {
        return (
          fieldName === name && (
            <span key={i}>{errors[fieldName]?.message} </span>
          )
        );
      })}
    </div>
  );
};

export default Input;
