import { Backdrop, Typography } from "@mui/material";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { saveAs } from "file-saver";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ImagePopup = ({ handleClose, open, img, isframe }) => {
  const saveFile = () => {
    saveAs(img);
  };

  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          display: "block",
        }}
        open={open}
        onClick={handleClose}
      >
        {isframe !== true && (
          <Box sx={{ flexGrow: 1 }}>
            <AppBar
              position="static"
              style={{ backgroundColor: "transparent", boxShadow: "none" }}
            >
              <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                  <ImageOutlinedIcon />
                </Typography>
                <FileDownloadOutlinedIcon
                  onClick={saveFile}
                  style={{ cursor: "pointer" }}
                />
              </Toolbar>
            </AppBar>
          </Box>
        )}

        <LazyLoadImage src={img} alt="image-message" className="image-modal" />
      </Backdrop>
    </div>
  );
};
export default ImagePopup;
